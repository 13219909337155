import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Pagination = () => {
	const { t } = useTranslation();
	return (
		<div className='booking-pagination booking-pagination--margin'>
			<Link to='/moviefull' className='booking-pagination__prev'>
				<span className='arrow__text arrow--prev'>{t('book2-prev')}</span>
				<span className='arrow__info'>{t('book2-movie')} &amp; {t('book2-time')}</span>
			</Link>
			<Link
				to='book3'
				href='book3-buy.html'
				className='booking-pagination__next'
			>
				<span className='arrow__text arrow--next'>{t('book2-next')}</span>
				<span className='arrow__info'>{t('book2-buy')}</span>
			</Link>
		</div>
	);
};

export default Pagination;
