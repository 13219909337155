import React from 'react';
import ChooseMovieTime from './ChooseMovie&Time/ChooseMovieTime';
import Order from './Order/Order';
import Pagination from './Pagination/Pagination';
import SilngleSlide from './SingleSlide/SilngleSlide';

export const Book1 = () => {
	return (
		<React.Fragment>
			{/* Main content */}
			<Order />
			<div className='choose-film'>
				<div className='swiper-container'>
					<div className='swiper-wrapper'>
						<SilngleSlide />
						<SilngleSlide />
						<SilngleSlide />
						<SilngleSlide />
					</div>
				</div>
			</div>
			<ChooseMovieTime />
			<div className='clearfix' />
			<Pagination />
			<div className='clearfix' />
		</React.Fragment>
	);
};
