import React from 'react';
import Boss from '../../../images/Boss-Level.jpg';

const SwiperPhoto = () => {

    return(
        <React.Fragment>
        <div className="swiper-slide media-photo"> 
            <a href={Boss} className="movie__media-item">
                <img alt='' src={Boss}/>
            </a>
        </div>

        </React.Fragment>
    );
}


export default SwiperPhoto;