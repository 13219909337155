import React from 'react';
import { useTranslation } from 'react-i18next';

const Ticket = () => {
	const { t } = useTranslation();
	return (
		<div className='ticket'>
			<div className='ticket-position'>
				<div className='ticket__indecator indecator--pre'>
					<div className='indecator-text pre--text'>{t('bookFinal-onlineTicket')}</div>{' '}
				</div>
				<div className='ticket__inner'>
					<div className='ticket-secondary'>
					<span className='ticket__item'>
							 <strong className='ticket__cinema'>{t('bookFinal-cinemaName')}</strong>
						</span>
						<span className='ticket__item'>
						{t('bookFinal-ticketNumber')}{' '}
							<strong className='ticket__number'>a126bym4</strong>
						</span>
						<span className='ticket__item ticket__date'>{t('bookFinal-date')}: <strong>25/10/2013</strong></span>
						<span className='ticket__item ticket__time'>{t('bookFinal-time')}: <strong>17:45</strong></span>						
						<span className='ticket__item'>
						{t('bookFinal-hall')}: <span className='ticket__hall'>Visconti</span>
						</span>
						<span className='ticket__item ticket__price'>
						{t('bookFinal-price')}: <strong className='ticket__cost'>30 {t('bookFinal-currency')}</strong>
						</span>
					</div>
					<div className='ticket-primery'>
						<span className='ticket__item ticket__item--primery ticket__film'>
						{t('bookFinal-movie')}
							<br />
							<strong className='ticket__movie'>The Fifth Estate (2013)</strong>
						</span>
						<span className='ticket__item ticket__item--primery'>
						{t('bookFinal-place')}: <span className='ticket__place'>11F, 12F, 13F</span>
						</span>
					</div>
				</div>
				<div className='ticket__indecator indecator--post'>
					<div className='indecator-text post--text'>{t('bookFinal-onlineTicket')}</div>
				</div>
			</div>
		</div>
	);
};

export default Ticket;
