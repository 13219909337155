import React from 'react';

const SilngleSlide = () => {
	return (
		<div className='swiper-slide' data-film='The Fifth Estate'>
			<div className='film-images'>
				<img alt='slide' src='http://placehold.it/380x600' />
			</div>
			<p className='choose-film__title'>The Fifth Estate</p>
		</div>
	);
};

export default SilngleSlide;
