import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Footer() {
	const { t } = useTranslation();
	return (
		<footer className='footer-wrapper'>
			<section className='container'>
				<div className='col-xs-4 col-md-2 footer-nav'>
					<ul className='nav-link'>
						<Link to='/movielist'>
							<li>
								<span className='nav-link__item'>{t('movies')}</span>
							</li>
						</Link>
						<Link to='/gallery'>
							<li>
								<span className='nav-link__item'>{t('gallery')}</span>
							</li>
						</Link>
						<Link to='/rulesofconduct'>
							<li>
								<span className='nav-link__item'>{t('rules')}</span>
							</li>
						</Link>
					</ul>
				</div>
				<div className='col-xs-4 col-md-2 footer-nav'>
					<ul className='nav-link'>
						<Link to='contact'>
							<li>
								<span className='nav-link__item'>{t('contact')}</span>
							</li>
						</Link>
						<Link to='carrier'>
							<li>
								<span className='nav-link__item'>{t('carrier')}</span>
							</li>
						</Link>
					</ul>
				</div>
				<div className='col-xs-4 col-md-2 footer-nav'>
					<ul className='nav-link'>
						<Link to='bastibubu'>
							<li>
								<span className='nav-link__item'>{t('bastiBubu-a')}</span>
							</li>
						</Link>
						<Link to='advertisement'>
							<li>
								<span className='nav-link__item'>{t('ads')}</span>
							</li>
						</Link>
					</ul>
				</div>
				<div className='col-xs-12 col-md-6'>
					<div className='footer-info'>
						<p className='heading-special--small'>
							{t('kinosakartvelo')}
							<br />
							<span className='title-edition'>{t('social')}</span>
						</p>
						<div className='social'>
							<a href='#0' className='social__variant fa fa-facebook' />
							<a href='#0' className='social__variant fa fa-instagram' />
							<a href='#0' className='social__variant fa fa-pinterest' />
						</div>
						<div className='clearfix' />
						<p className='copy'>© {t('kinosakartvelo')} 2021</p>
					</div>
				</div>
			</section>
		</footer>
	);
}

export default Footer;
