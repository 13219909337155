import React from 'react';

const Genre = () => {
	return (
		<React.Fragment>
			<a href='#0'>სათავგადასავლო</a>
		</React.Fragment>
	);
};

export default Genre;
