import React from 'react';
import ReactDOM from 'react-dom';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import './index.css';
import './css/timeoutModal.css';
import './css/external/idangerous.swiper.css';
import './css/external/jquery.selectbox.css';
import './css/external/magnific-popup.css';
import App from './App';

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.use(LanguageDetector)
	.use(HttpApi)
	.init({
		fallbackLng: 'ge',
		detection: {
			order: ['localStorage', 'cookie', 'htmlTag', 'path', 'subdomain'],
			caches: ['localStorage'],
		},
		backend: {
			loadPath: '/assets/locales/{{lng}}/translation.json',
		},
		react: { useSuspense: false },
	});

ReactDOM.render(<App />, document.getElementById('root'));
