import React from 'react';
import image from '../../../images/tickets.png';
import Ticket from './Ticket/Ticket';
import TicketControl from './TicketControl/TicketControl';
import { useTranslation } from 'react-i18next';

const BookFinal = () => {
	const { t } = useTranslation();
	return (
		<React.Fragment>
			{/* Main content */}
			<section className='container'>
				<div id='ex1' className='modal'>
					<p>Thanks for clicking. That felt good.</p>
					<a href='#0' rel='modal:close'>
						Close
					</a>
				</div>
				<div className='wrapper place-wrapper'>
					<div className='order-container'>
						<div className='order'>
							<img className='order__images' alt='true' src={image} />
							<p className='order__title'>
							{t('bookFinal-title')}! <br />
								<span className='order__descript'>
								{t('bookFinal-descrpt')}
								</span>
							</p>
						</div>
						<Ticket />
						<TicketControl />
					</div>
				</div>
			</section>
			<div className='clearfix' />
		</React.Fragment>
	);
};

export default BookFinal;
