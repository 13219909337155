import React from 'react';
import { Link } from 'react-router-dom';
import errorImage from '../../images/error.png';
import { useTranslation } from 'react-i18next';

const ErrorPage = () => {
	const { t } = useTranslation();
	return (
		<div className='wrapper'>
			<div className='error-wrapper'>
				<div className='error'>
					<img alt='true' src={errorImage} className='error__image' />
					<h1 className='error__text'>{t('erroPage-title')}</h1>
					<Link to='/' className='btn btn-md btn--warning'>
					{t('erroPage-button')}
					</Link>
				</div>
			</div>
		</div>
	);
};

export default ErrorPage;
