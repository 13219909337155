import React, { useEffect } from 'react';
import SilngeMovie from './SingleMovie/SilngeMovie';
import $ from 'jquery';
import jQuery from 'jquery';
import { useTranslation } from 'react-i18next';
window.$ = $;
window.jQuery = $;

function BestMovies({ data }) {
	const { t } = useTranslation();
	let bestMoviesData = data.slice(0, 6); // <-- 6 item in section

	useEffect(() => {
		//5. Scroll down navigation function
		//scroll down
		$('.movie-best__check').click(function (ev) {
			ev.preventDefault();
			$('html, body')
				.stop()
				.animate({ scrollTop: $('#target').offset().top - 30 }, 900, 'swing');
		});
	}, []);

	return (
		<div className='container'>
			<div className='movie-best'>
				<div className='col-sm-10 col-sm-offset-1 movie-best__rating'>
				{t('bestMovies-choise')}
				</div>
				<div className='col-sm-12 change--col'>
					{bestMoviesData.map((movie, index) => {
						return <SilngeMovie key={index} singleData={movie} />;
					})}
				</div>
				{/*Change Col End */}
				<div className='col-sm-10 col-sm-offset-1 movie-best__check'>
				{t('bestMovies-allMovies')}
				</div>
			</div>
		</div>
	);
}

export default BestMovies;
