import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function SingleInCinema({ movie, getMovieId }) {
	const { t } = useTranslation();
	return (
		<div
			onClick={() => getMovieId(movie.id)}
			className='movie movie--test--new  movie--card--new'
		>
			<div className='movie--images--new'>
				<img src={`https://image.tmdb.org/t/p/original/${movie.poster_path}`} />
				<Link to='/moviefull' className='movie-links-post'></Link>
				<div className='movie--info--new'>
					<Link to='/moviefull' className='movie-links-post'></Link>
					<Link to='/moviefull' className='movie__title movie__title--new'>
						{movie.title}
					</Link>
					<p className='movie__option movie__option--new'>
						{t('nowInCinema-genre')}: მძაფრსიუჟეტიანი, სათავგადასავლო, ტრილერი
					</p>
					<p className='movie__time__new'>91 {t('nowInCinema-time')}</p>
					<a
						className='read-more trailer-button additional-margin-for-trailer-button after-disable '
						href='#onCLickModal'
						rel='modal:open'
					>
						{t('nowInCinema-trailer')}
					</a>
					<Link to='/moviefull' className='read-more trailer-button'>
						{t('nowInCinema-more')}
					</Link>
				</div>
			</div>
		</div>
	);
}

export default SingleInCinema;
