import React from "react";
import { useTranslation } from 'react-i18next';

const SearchBar = () => {
  const { t } = useTranslation();
  return (
    <div className="search-wrapper search--cont--new">
  <div className="container container--add">
    <form id="search-form" method="get" className="search">
      <input type="text" className="search__field" placeholder={t('movieListFull-search')} />
      <select name="sorting_item" id="search-sort" className="search__sort" tabIndex={0}>
        <option value={1} defaultValue='selected'>სათაურით</option>
        <option value={2}>წლით</option>
        <option value={3}>პროდიუსერით</option>
        <option value={4}>ხანგრზლივობით</option>
        <option value={5}>წლით</option>
      </select>
      <button type="submit" className="btn btn-md btn--danger search__button">{t('movieListFull-searchButton')}</button>
    </form>
  </div>
</div>

  );
};

export default SearchBar;
