import React from 'react';
import banner from '../../images/banners/banner_2.jpg';

function BannerRigth() {
	return (
		<div className='banner--right'>
			<img alt='banner' src={banner} />
		</div>
	);
}

export default BannerRigth;
