import React from 'react';
import { useTranslation } from 'react-i18next';

const SinglePhoto = () => {
    const { t } = useTranslation();
    return (
        <div className="col-sm-4">
        <div className="gallery-item">
            <a href="https://images.unsplash.com/photo-1608170825938-a8ea0305d46c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=925&q=80" className="gallery-item__image gallery-item--photo">
                <img src='https://cdn.wallpapersafari.com/22/61/NWEx1Y.jpg' />
            </a>
            <a href="https://images.unsplash.com/photo-1608170825938-a8ea0305d46c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=925&q=80" className="gallery-item__descript gallery-item--photo-link">
                <span className="gallery-item__icon"><i className="fa fa-camera" /></span>
                <p className="gallery-item__name">{t('gallery-photo')}</p>
            </a>
        </div>
      </div>
    )
}

export default SinglePhoto
