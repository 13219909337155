import React from 'react'
import { useTranslation } from 'react-i18next';

const SingleVideo = () => {
    const { t } = useTranslation();
    return (
        <div className="col-sm-4">
            <div className="gallery-item">
                <a href="https://www.youtube.com/watch?v=_T5gwk16oEc" className="gallery-item__image gallery-item--video">
                    <img  src="https://i.ytimg.com/vi/As2S8dxn-ro/maxresdefault.jpg" />
                </a>
                <a href="https://www.youtube.com/watch?v=_T5gwk16oEc" className="gallery-item__descript gallery-item--video-link">
                    <span className="gallery-item__icon"><i className="fa fa-video-camera" /></span>
                    <p className="gallery-item__name">{t('gallery-video')}</p>
                </a>
            </div>
        </div>          
    )
}

export default SingleVideo
