import React from 'react';
import { useTranslation } from 'react-i18next';

const Steps = () => {
	const { t } = useTranslation();
	return (
		<div className='order-step-area'>
			<div className='order-step first--step order-step--disable '>
				1. {t('book3Buy-movie')} &amp; {t('book3Buy-time')}
			</div>
			<div className='order-step second--step order-step--disable'>
				2. {t('book3Buy-chooseSeat2')}
			</div>
			<div className='order-step third--step'>3. {t('book3Buy-buyTicket2')}</div>
		</div>
	);
};

export default Steps;
