import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TicketControl = () => {
	const { t } = useTranslation();
	return (
		<div className='ticket-control'>
			<Link to='#0' className='watchlist list--download'>
			{t('bookFinal-download')}
			</Link>
			<Link to='#0' className='watchlist list--print'>
			{t('bookFinal-print')}
			</Link>
		</div>
	);
};

export default TicketControl;
