import React from 'react';
const CarouselItem = ({ carData }) => {
	return (
		<React.Fragment>
			<div className='owl-item'>
				<div className='carousel-post-container'>
					<div className='post__image'>
						<img
							alt='carousel'
							src={`https://image.tmdb.org/t/p/original/${carData.poster_path}`}
						/>
						<div className='movie-links-post'>
							<div className='movie--post--heads'>
								<a href='new-coming-soon.html' className='post__title'>
									{carData.title}
								</a>
								<a
									href='new-coming-soon.html'
									className='btn read-more post--btn'
								>
									მეტის ნახვა
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default CarouselItem;
