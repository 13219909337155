import React from 'react';
import { useTranslation } from 'react-i18next';

const Contact = () => {
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<section className='container'>
				<h2 className='page-heading heading--outcontainer'>{t('contact')}</h2>
				<div className='contact'>
					<p className='contact__title'>
						{t('address')}
						<br />
						<span className='contact__describe'>{t('describe')}</span>
					</p>
					<span className='contact__mail'>info@kinosakartvelo.ge</span>
					<span className='contact__tel'>+995 431 23 01 10</span>
				</div>
			</section>
			<div className='contact-form-wrapper'>
				<div className='container'>
					<div className='col-sm-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2'>
						<form
							id='contact-form'
							className='form row'
							method='post'
							noValidate
							action='send.php'
						>
							<p className='form__title'>{t('title')}</p>
							<div className='col-sm-6'>
								<input
									type='text'
									placeholder={t('name')}
									name='user-name'
									className='form__name'
								/>
							</div>
							<div className='col-sm-6'>
								<input
									type='email'
									placeholder={t('email')}
									name='user-email'
									className='form__mail'
								/>
							</div>
							<div className='col-sm-12'>
								<textarea
									placeholder={t('message')}
									name='user-message'
									className='form__message'
									defaultValue={''}
								/>
							</div>
							<button type='submit' className='btn btn-md btn--danger'>
								{t('send')}
							</button>
						</form>
					</div>
				</div>
			</div>
			<section className='container'>
				<div className='contact'>
					<p className='contact__title'>
						{t('location')}
						<br />
					</p>
				</div>
			</section>
			<div className='map'>
				{' '}
				<iframe
					title='ifm'
					src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2952.4251900167847!2d42.702989515895766!3d42.2694462791931!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x405c8db63f72134f%3A0x856a818e3c1dcdac!2z4YOZ4YOY4YOc4YOdIOGDoeGDkOGDpeGDkOGDoOGDl-GDleGDlOGDmuGDnSAvIENpbmVtYSBTYWthcnR2ZWxv!5e0!3m2!1sru!2sge!4v1616358622702!5m2!1sru!2sge'
					width='100%'
					height={400}
					style={{ border: 0 }}
					allowFullScreen
					loading='lazy'
				/>
			</div>
		</React.Fragment>
	);
};

export default Contact;
