import React from 'react';
import { useTranslation } from 'react-i18next';

const BastiBubu = () => {
	const { t } = useTranslation();
	return (
		<section className='container'>
			<div className='overflow-wrapper'>
				<div className='col-sm-12'>
					{/* <h2 class="page-heading">News</h2> */}
					{/* News post article*/}
					<article className='post post--news my-article'>
						<h1>
							<a href='#0' className='post__title-link'>
								{t('bastiBubu-a')}
							</a>
						</h1>
						<div className='wave-devider' />
						<h4>{t('bastiBubu-a')}</h4>
						<div>
							<p>14.11.2019</p>
							<p>{t('bastiBubu-p')}</p>
							<p> {t('bastiBubu-p2')}</p>
						</div>
						<div className='devider-huge' />
					</article>
				</div>
			</div>
		</section>
	);
};

export default BastiBubu;
