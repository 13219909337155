import React, { useEffect } from 'react';
import $ from 'jquery';
import jQuery from 'jquery';

const TopScroll = () => {
	useEffect(() => {
		/**
		 * author Remy Sharp
		 * url http://remysharp.com/2009/01/26/element-in-view-event-plugin/
		 */
		(function ($) {
			function getViewportHeight() {
				var height = window.innerHeight; // Safari, Opera
				var mode = document.compatMode;

				if (mode || !$.support.boxModel) {
					// IE, Gecko
					height =
						mode === 'CSS1Compat'
							? document.documentElement.clientHeight // Standards
							: document.body.clientHeight; // Quirks
				}

				return height;
			}

			$(window).scroll(function () {
				var vpH = getViewportHeight(),
					scrolltop = document.documentElement.scrollTop
						? document.documentElement.scrollTop
						: document.body.scrollTop,
					elems = [];

				// naughty, but this is how it knows which elements to check for
				$.each($.cache, function () {
					if (this.events && this.events.inview) {
						elems.push(this.handle.elem);
					}
				});

				if (elems.length) {
					$(elems).each(function () {
						var $el = $(this),
							top = $el.offset().top,
							height = $el.height(),
							inview = $el.data('inview') || false;

						if (scrolltop > top + height || scrolltop + vpH < top) {
							if (inview) {
								$el.data('inview', false);
								$el.trigger('inview', [false]);
							}
						} else if (scrolltop < top + height) {
							if (!inview) {
								$el.data('inview', true);
								$el.trigger('inview', [true]);
							}
						}
					});
				}
			});
			// kick the event to pick up any elements already in view.
			// note however, this only works if the plugin is included after the elements are bound to 'inview'
			$(function () {
				$(window).scroll();
			});
		})(jQuery);
	}, []);

	useEffect(() => {
		//1. Scroll to top arrow
		// Scroll to top
		var $block = $('<div/>', { class: 'top-scroll' })
			.append('<a href="#"/>')
			.hide()
			.appendTo('body')
			.click(function () {
				$('body,html').animate({ scrollTop: 0 }, 800);
				return false;
			});
		//initialization
		var $window = $(window);

		if ($window.scrollTop() > 35) {
			showElem();
		} else {
			hideElem();
		}

		//handlers
		$window.scroll(function () {
			if ($(this).scrollTop() > 35) {
				showElem();
			} else {
				hideElem();
			}
		});

		//functions
		function hideElem() {
			$block.fadeOut();
		}
		function showElem() {
			$block.fadeIn();
		}
	}, []);

	return (
		<React.Fragment>
			{/* <div className="top-scroll">
            <a href="#"></a>
        </div> */}
		</React.Fragment>
	);
};

export default TopScroll;
