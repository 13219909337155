import React from 'react';
import { useTranslation } from 'react-i18next';

const Advertisement = () => {
  const { t } = useTranslation();
	return (
		<section className='container'>
			<div className='overflow-wrapper'>
				<div className='col-sm-12'>
					{/* <h2 class="page-heading">News</h2> */}
					{/* News post article*/}
					<article className='post post--news'>
						<h1>
							<a href='#0' className='post__title-link'>
                {t('advertisement-title')}
							</a>
						</h1>
						<div className='wave-devider' />
            <h5>{t('advertisement-h5')}</h5>
						<div>
							<p className='post__text'>
                {t('advertisement-p')}
							</p>
              
              <h5>{t('advertisement-h5.2')}</h5>
							<p className='post__text'>
                {t('advertisement-p2')}
							</p>
              <h5>{t('advertisement-h5.3')}</h5>
							<p className='post__text'>
                {t('advertisement-p3')}
							</p>
              <h5>{t('advertisement-h5.4')}</h5>
							<p className='post__text'>
                {t('advertisement-p4')}
							</p>
              <h5>{t('advertisement-h5.5')}</h5>
							<ul className="classForAdvertisement">
								<li>
                  {t('advertisement-li')}
               </li>
                <li>{t('advertisement-li2')}</li>
								<li>
                  {t('advertisement-li3')}
								</li>
                <li>{t('advertisement-li4')}</li>
								<li>
                  {t('advertisement-li5')}
								</li>
                <li>{t('advertisement-li6')}</li>
                <li>{t('advertisement-li7')}</li>
                <li>{t('advertisement-li8')}</li>
                <li>{t('advertisement-li9')}</li>
                <li>{t('advertisement-li10')}</li>
                <li>{t('advertisement-li11')}</li>
							</ul>
						</div>
						<div className='devider-huge' />
					</article>
				</div>
			</div>
		</section>
	);
};

export default Advertisement;
