import React from 'react';
import image from '../../../../images/tickets.png';

const Order = () => {
	return (
		<section className='container'>
			<div className='order-container'>
				<div className='order'>
					<img className='order__images' alt='imagez' src={image} />
					<p className='order__title'>
						დაჯავშნე ბილეთი <br />
						<span className='order__descript'>
							გისურვებთ სასიამოვნო ყურებას
						</span>
					</p>
					<div className='order__control'>
						<a href='#0' className='order__control-btn active'>
							შეძენა
						</a>
						<a href='#0' className='order__control-btn'>
							დაჯავშვნა
						</a>
					</div>
				</div>
			</div>

			<h2 className='page-heading heading--outcontainer'>ფილმის არჩევა</h2>
		</section>
	);
};

export default Order;
