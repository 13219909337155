import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Pagination from './Pagination/Pagination';
import Seats from './Seats/Seats';
import image from '../../../images/tickets.png';
import $ from 'jquery';

const Book2 = () => {
	const { t } = useTranslation();

	useEffect(() => {
		//1. Buttons for choose order method
		//order factor
		$('.order__control-btn').click(function (e) {
			e.preventDefault();

			$('.order__control-btn').removeClass('active');
			$(this).addClass('active');
		});

		//2. Init vars for order data
		// var for booking;
		var numberTicket = $('.choosen-number'),
			sumTicket = $('.choosen-cost'),
			cheapTicket = $('.choosen-number--cheap'),
			middleTicket = $('.choosen-number--middle'),
			expansiveTicket = $('.choosen-number--expansive'),
			sits = $('.choosen-sits');

		//3. Choose sits (and count price for them)
		//users choose sits

		//data elements init
		var sum = 0;
		var cheap = 0;
		var middle = 0;
		var expansive = 0;

		$('.sits__place').click(function (e) {
			e.preventDefault();
			var place = $(this).attr('data-place');
			var ticketPrice = $(this).attr('data-price');

			if (!$(e.target).hasClass('sits-state--your')) {
				if (!$(this).hasClass('sits-state--not')) {
					$(this).addClass('sits-state--your');

					$('.checked-place').prepend(
						'<span class="choosen-place ' + place + '">' + place + '</span>'
					);

					switch (ticketPrice) {
						case '10':
							sum += 10;
							cheap += 1;
							break;
						case '20':
							sum += 20;
							middle += 1;
							break;
						case '30':
							sum += 30;
							expansive += 1;
							break;
					}

					$('.checked-result').text(sum + '₾');
				}
			} else {
				$(this).removeClass('sits-state--your');

				$('.' + place + '').remove();

				switch (ticketPrice) {
					case '10':
						sum -= 10;
						cheap -= 1;
						break;
					case '20':
						sum -= 20;
						middle -= 1;
						break;
					case '30':
						sum -= 30;
						expansive -= 1;
						break;
				}

				$('.checked-result').text(sum + '₾');
			}

			//data element init
			var number = $('.checked-place').children().length;

			//data element set
			numberTicket.val(number);
			sumTicket.val(sum);
			cheapTicket.val(cheap);
			middleTicket.val(middle);
			expansiveTicket.val(expansive);

			//data element init
			var chooseSits = '';
			$('.choosen-place').each(function () {
				chooseSits += ', ' + $(this).text();
			});

			//data element set
			sits.val(chooseSits.substr(2));
		});

		//--- Step for data  ---//
		//Get data from pvevius page
		var url = decodeURIComponent(document.URL);
		var prevDate = url.substr(url.indexOf('?') + 1);

		//Serialize, add new data and send to next page
		$('.booking-form').submit(function (e) {
			e.preventDefault();
			var bookData = $(this).serialize();

			var fullData = prevDate + '&' + bookData;
			var action,
				control = $('.order__control-btn.active').text();

			if (control === 'Purchase') {
				action = 'book3-buy.html';
			} else if (control === 'Reserve') {
				action = 'book3-reserve.html';
			}

			$.get(action, fullData, function (data) {});
		});

		$('.top-scroll').parent().find('.top-scroll').remove();
	}, []);

	return (
		<React.Fragment>
			{/* Main content */}
			<div className='place-form-area'>
				<section className='container'>
					<div className='order-container'>
						<div className='order'>
							<img className='order__images' alt='true' src={image} />
							<p className='order__title'>
							{t('book2-title')}
								<br />
								<span className='order__descript'>
								{t('book2-span')}
								</span>
							</p>
						</div>
					</div>
					<div className='order-step-area'>
						<div className='order-step first--step order-step--disable '>
							1. {t('book2-movie')} &amp; {t('book2-time')}
						</div>
						<div className='order-step second--step'>2. {t('book2-chooseSeat')}</div>
					</div>
					<div className='choose-sits'>
						<Seats />
					</div>
				</section>
			</div>
			<div className='clearfix' />
			<form
				id='film-and-time'
				className='booking-form'
				method='get'
				action='book3-buy.html'
			>
				<input type='text' name='choosen-number' className='choosen-number' />
				<input
					type='text'
					name='choosen-number--cheap'
					className='choosen-number--cheap'
				/>
				<input
					type='text'
					name='choosen-number--middle'
					className='choosen-number--middle'
				/>
				<input
					type='text'
					name='choosen-number--expansive'
					className='choosen-number--expansive'
				/>
				<input type='text' name='choosen-cost' className='choosen-cost' />
				<input type='text' name='choosen-sits' className='choosen-sits' />
				<Pagination />
			</form>
			<div className='clearfix' />
		</React.Fragment>
	);
};

export default Book2;
