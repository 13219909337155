import React from 'react';
import paypallImage from '../../../../images/payment/pay1.png';
import visaImage from '../../../../images/payment/pay7.png';
import masterCardImage from '../../../../images/payment/pay6.png';
import { useTranslation } from 'react-i18next';

const MethodOfPayment = () => {
	const { t } = useTranslation();
	return (
		<React.Fragment>
			<h2 className='page-heading'>{t('book3Buy-methodOfPay')}</h2>
			<div className='payment'>
				<a href='#0' className='payment__item'>
					<img alt='true' src={paypallImage} />
				</a>
				<a href='#0' className='payment__item'>
					<img alt='true' src={masterCardImage} />
				</a>
				<a href='#0' className='payment__item'>
					<img alt='true' src={visaImage} />
				</a>
			</div>
		</React.Fragment>
	);
};

export default MethodOfPayment;
