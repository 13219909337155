import React, { useEffect } from 'react';
import $ from 'jquery';

const OnClickModal = () => {
	useEffect(() => {
		// Get the modal
		var modal = document.getElementById('onCLickModal');

		// When the user clicks anywhere outside of the modal, close it
		window.onclick = function (event) {
			if (event.target == modal) {
				modal.style.display = 'none';
				$('#onCLickModal iframe').attr(
					'src',
					$('#onCLickModal iframe').attr('src')
				); // <-- remove & add src
			}
		};
	}, []);

	return (
		<React.Fragment>
			<div id='onCLickModal' className='modal'>
				<div className='modal-content'>
					<a href='#close-modal' className='close2' rel='modal:close'>
						&times;
					</a>
					<iframe
						className='video-player--modal'
						width='560'
						height='315'
						src='https://www.youtube.com/embed/9Yam5B_iasY'
						title='YouTube video player'
						frameBorder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						allowFullScreen
					></iframe>
				</div>
			</div>
		</React.Fragment>
	);
};

export default OnClickModal;
