import React from 'react';

const Actors = () => {
	return (
		<React.Fragment>
			<a href='#0'>Army Adams</a>
		</React.Fragment>
	);
};

export default Actors;
