import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Pagination = () => {
	const { t } = useTranslation();
	return (
		<div className='booking-pagination'>
			<Link to='/book2' className='booking-pagination__prev'>
				<p className='arrow__text arrow--prev'>{t('book3Buy-prev')}</p>
				<span className='arrow__info'>{t('book3Buy-chooseSeat')}</span>
			</Link>
		</div>
	);
};

export default Pagination;
