import React from 'react';
import banner from '../../images/banners/banner_fliped.jpg';

function HorizontalBanner() {
	return (
		<div className='container'>
			<div className='banner--horizontal'>
				<img alt='banner' src={banner} />
			</div>
		</div>
	);
}

export default HorizontalBanner;
