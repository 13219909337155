import React from 'react';
import BannerLeft from '../Banner/BannerLeft';
import BannerRigth from '../Banner/BannerRigth';
import SingleInCinema from './SingleInCinema/SingleInCinema';
import { useTranslation } from 'react-i18next';

function NowInCinema({ data, getMovieId }) {
	const { t } = useTranslation();
	return (
		<div className='container'>
			<div className='col-sm-12 col__sm__container'>
				<h2 id='target' className='page-heading heading--outcontainer'>
				{t('nowInCinema-title')}
				</h2>
				<BannerLeft />
				<BannerRigth />
				<div className='row myRow'>
					<div className='col-sm-8 col-md-9 col-md-9-new'>
						{data.map((movie, index) => {
							return (
								<SingleInCinema
									key={index}
									movie={movie}
									getMovieId={getMovieId}
								/>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
}

export default NowInCinema;
