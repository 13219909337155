import React from 'react';

const ChooseMovieTime = () => {
	return (
		<section className='container'>
			<div className='col-sm-12'>
				<div className='choose-indector choose-indector--film'>
					<strong>არჩეული: </strong>
					<span className='choosen-area' />
				</div>
				<h2 className='page-heading'>აირჩიეთ თარიღი</h2>
				<div className='choose-container choose-container--short'>
					<div className='datepicker'>
						<span className='datepicker__marker'>
							<i className='fa fa-calendar' />
							თარიღი
						</span>
						<input
							type='text'
							id='datepicker'
							defaultValue='03/10/2014'
							className='datepicker__input'
						/>
					</div>
				</div>
				<h2 className='page-heading'>აირჩიეთ დრო</h2>
				<div className='time-select time-select--wide'>
					<div className='time-select__group group--first'>
						<div className='col-sm-3'>
							<p className='time-select__place'>გოდზილა</p>
						</div>
						<ul className='col-sm-6 items-wrap'>
							<li className='time-select__item' data-time='09:40'>
								09:40
							</li>
							<li className='time-select__item' data-time='13:45'>
								13:45
							</li>
							<li className='time-select__item' data-time='15:45'>
								15:45
							</li>
							<li className='time-select__item' data-time='19:50'>
								19:50
							</li>
							<li className='time-select__item' data-time='21:50'>
								21:50
							</li>
						</ul>
					</div>
					<div className='choose-indector choose-indector--time'>
						<strong>არჩეული: </strong>
						<span className='choosen-area' />
					</div>
				</div>
			</div>
		</section>
	);
};

export default ChooseMovieTime;
