import React from 'react';
import { useTranslation } from 'react-i18next';

const RulesOfConduct = () => {
	const { t } = useTranslation();
	return (
		<section className='container'>
			<div className='overflow-wrapper'>
				<div className='col-sm-12'>
					{/* <h2 class="page-heading">News</h2> */}
					{/* News post article*/}
					<article className='post post--news'>
						<h1>
							<a href='#0' className='post__title-link'>
								{t('rulesOfConduct-title')}
							</a>
						</h1>
						<p className='post__date'>1.06.2021</p>
						<div className='wave-devider' />
						<div>
							<p className='post__text'>1. {t('rulesOfConduct-1')}</p>
							<p className='post__text'>1.1. {t('rulesOfConduct-1.1')}</p>
							<p className='post__text'>1.2. {t('rulesOfConduct-1.2')}</p>
							<p className='post__text'>1.3. {t('rulesOfConduct-1.3')}</p>
							<p className='post__text'>1.4. {t('rulesOfConduct-1.4')}</p>
							<p className='post__text'>1.5. {t('rulesOfConduct-1.5')}</p>
							<p className='post__text'>1.6. {t('rulesOfConduct-1.6')}</p>
							<p className='post__text'>1.7. {t('rulesOfConduct-1.7')}</p>
							<p className='post__text'>1.8. {t('rulesOfConduct-1.8')}</p>
							<p className='post__text'>1.9. {t('rulesOfConduct-1.9')}</p>
							<p className='post__text'>1.10. {t('rulesOfConduct-1.10')}</p>
							<p className='post__text'>1.11. {t('rulesOfConduct-1.11')}</p>
							<p className='post__text'>1.12. {t('rulesOfConduct-1.12')}</p>
							<p className='post__text'>1.13. {t('rulesOfConduct-1.13')}</p>
							<p className='post__text'>1.14. {t('rulesOfConduct-1.4')}</p>
							<p className='post__text'>2. {t('rulesOfConduct-2')}</p>
							<p className='post__text'>2.1. {t('rulesOfConduct-2.1')}</p>
							<p className='post__text'>2.2. {t('rulesOfConduct-2.2')}</p>
							<p className='post__text'>2.3. {t('rulesOfConduct-2.3')}</p>
						</div>
						<div className='tags'>
							<ul>
								<li className='item-wrap'>
									<a href='#0' className='tags__item'>
										{t('rulesOfConduct-a')}
									</a>
								</li>
								<li className='item-wrap'>
									<a href='#0' className='tags__item'>
										{t('rulesOfConduct-a2')}
									</a>
								</li>
							</ul>
						</div>
						<div className='devider-huge' />
					</article>
				</div>
			</div>
		</section>
	);
};

export default RulesOfConduct;
