import React from 'react';
import { useTranslation } from 'react-i18next';

const Price = () => {
	const { t } = useTranslation();
	return (
		<React.Fragment>
			<h2 className='page-heading'>{t('book3Buy-price')}</h2>
			<ul className='book-result'>
				<li className='book-result__item'>
				{t('book3Buy-tickets')}: <span className='book-result__count booking-ticket'>3</span>
				</li>
				<li className='book-result__item'>
				{t('book3Buy-oneTicket')}:{' '}
					<span className='book-result__count booking-price'>20 {t('book3Buy-currency')}</span>
				</li>
				<li className='book-result__item'>
				{t('book3Buy-total')}: <span className='book-result__count booking-cost'>60 {t('book3Buy-currency')}</span>
				</li>
			</ul>
		</React.Fragment>
	);
};

export default Price;
