import React, { useState, useEffect } from 'react';
import BestMovies from './Components/BestMovies/BestMovies';
import ComingSoon from './Components/ComingSoon/ComingSoon';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import NowInCinema from './Components/NowInCinema/NowInCinema';
import OnClickNodal from './Components/Modals/OnClickModal/OnClickModal';
import HorizontalBanner from './Components/Banner/HorizontalBanner';
import HorizontalBanner2 from './Components/Banner/HorizontalBanner2';
import AnimatedSlider from './Components/AnimatedSlider/AnimatedSlider';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Advertisement from './Components/Advertisement/Advertisement';
import BastiBubu from './Components/BastiBubu/BastiBubu';
import Carrier from './Components/Carrier/Carrier';
import Contact from './Components/Contact/Contact';
import Gallery from './Components/Gallery/Gallery';
import RulesOfConduct from './Components/RulesOfConduct/RulesOfConduct';
import MovieListFull from './Components/MovieListFull/MovieListFull';
import LoaderModal from './Components/Modals/LoaderModal/LoaderModal';
import TopScroll from './Components/TopScroll/TopScroll';
import MovieFull from './Components/MovieFull/MovieFull';
import { Book1 } from './Components/Book/Book1/Book1';
import Book2 from './Components/Book/Book2/Book2';
import Book3Buy from './Components/Book/Book3-Buy/Book3Buy';
import BookFinal from './Components/Book/BookFinal/BookFinal';
import ErrorPage from './Components/ErrorPage/ErrorPage';
import Loader from './Components/Loader/Loader';
import axios from 'axios';

function App() {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [movieId, setMovieId] = useState('');
	const KEY = 'b75b4d6bd408a867b3b8d4bee48a13a5';

	const getMovieId = (id) => {
		setMovieId(id);
	};

	const apiCall = async () => {
		try {
			setLoading(true);
			const result = await axios(
				`https://api.themoviedb.org/3/movie/now_playing?api_key=${KEY}`
			);
			setData(result.data.results);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	function Home() {
		return (
			<div>
				<AnimatedSlider data={data} />
				<OnClickNodal />
				<BestMovies data={data} />
				<HorizontalBanner />
				<NowInCinema data={data} apiCall={apiCall} getMovieId={getMovieId} />
				<HorizontalBanner2 />
				<ComingSoon data={data} />
				<TopScroll />
			</div>
		);
	}

	useEffect(() => {
		apiCall();
	}, []);

	return (
		<Router>
			{loading && <Loader />}
			<div className='wrapper'>
				<Header />
				<Switch>
					<Route path='/movielist' exact component={MovieListFull} />
					<Route path='/' exact component={Home} />
					<Route path='/advertisement' component={Advertisement} />
					<Route path='/bastibubu' component={BastiBubu} />
					<Route path='/carrier' component={Carrier} />
					<Route path='/contact' component={Contact} />
					<Route path='/gallery' component={Gallery} />
					<Route path='/rulesofconduct' component={RulesOfConduct} />
					<Route
						path='/moviefull'
						render={() => (
							<MovieFull data={data} movieId={movieId} isAuthed={true} />
						)}
					/>
					<Route path='/book1' component={Book1} />
					<Route path='/book2' component={Book2} />
					<Route path='/book3' component={Book3Buy} />
					<Route path='/bookfinal' component={BookFinal} />
					<Route path='/errorpage' component={ErrorPage} />
				</Switch>
			</div>
			<Footer />
			<LoaderModal />
		</Router>
	);
}

export default App;
