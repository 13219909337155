import React from 'react';
import banner from '../../images/banners/banner_fliped.jpg';

const HorizontalBanner2 = () => {
	return (
		<div className='container'>
			<div className='banner2--horizontal'>
				<img alt='banner' src={banner} />
			</div>
		</div>
	);
};

export default HorizontalBanner2;
