import React from 'react';
import image from '../../../images/tickets.png';
import { Link } from 'react-router-dom';
import Pagination from './Pagination/Pagination';
import ContactInfo from './Contact-Info/ContactInfo';
import MethodOfPayment from './MethodOfPayment/MethodOfPayment';
import Price from './Price/Price';
import Steps from './Steps/Steps';
import { useTranslation } from 'react-i18next';

const Book3Buy = () => {
	const { t } = useTranslation();
	return (
		<React.Fragment>
			{/* Main content */}
			<section className='container'>
				<div className='order-container'>
					<div className='order'>
						<img className='order__images' alt='true' src={image} />
						<p className='order__title'>
						{t('book3Buy-title')}
							<br />
							<span className='order__descript'>
							{t('book3Buy-span')}
							</span>
						</p>
						``{' '}
					</div>
				</div>
				<Steps />
				<div className='col-sm-12'>
					<div className='checkout-wrapper'>
						<Price />
						<MethodOfPayment />
						<ContactInfo />
					</div>
					<div className='order'>
						<Link to='/bookfinal' className='btn btn-md btn--warning btn--wide'>
						{t('book3Buy-buyTicket')}
						</Link>
					</div>
				</div>
			</section>
			<div className='clearfix' />
			<Pagination />

			<div className='clearfix' />
		</React.Fragment>
	);
};

export default Book3Buy;
