import React from 'react';
import { useTranslation } from 'react-i18next';
const MovieListSingle = () => {
	const { t } = useTranslation();
	return (
		<React.Fragment>
			<div className='movie movie--preview movie--full release movie--preview--new'>
				<div className='col-sm-3 col-md-2 '>
					<div className='movie__images'>
						<img src='https://cinemazero.it/media/photologue/photos/temp/cache/guuuC6YZrk2HTozNmoJ29PUfGxo_movie_list.jpg' />
					</div>
					<div className='movie__feature movie--feature--my'>
						<a
							href='#0'
							className='movie__feature-item movie__feature--comment'
						>
							123
						</a>
						<a href='#0' className='movie__feature-item movie__feature--video'>
							7
						</a>
						<a href='#0' className='movie__feature-item movie__feature--photo'>
							352
						</a>
					</div>
				</div>
				<div className='col-sm-9 col-md-10 col-lg-10 movie__about'>
					<a href='movie-page-full.html' className='movie__title link--huge'>
						Last Vegas (2013)
					</a>
					<p className='movie__time movie--feature--my'>105 {t('movieListFull-time')}</p>
					<p className='movie__option'>
						<strong>{t('movieListFull-country')}: </strong>
						<a href='#0'>აშშ</a>
					</p>
					<p className='movie__option'>
						<strong>{t('movieListFull-genre')}: </strong>
						<a href='#0'>კომედია</a>
					</p>
					<p className='movie__option'>
						<strong>{t('movieListFull-releaseDate')}: </strong>ნოემბერი 1, 2013
					</p>
					<p className='movie__option'>
						<strong>{t('movieListFull-director')}: </strong>
						<a href='#0'>Jon Turteltaub</a>
					</p>
					<p className='movie__option'>
						<strong>{t('movieListFull-cast')}: </strong>
						<a href='#0'>Robert De Niro</a>, <a href='#0'>Michael Douglas</a>,{' '}
						<a href='#0'>Morgan Freeman</a>, <a href='#0'>Kevin Kline</a>,{' '}
						<a href='#0'>Mary Steenburgen</a>, <a href='#0'>Jerry Ferrara</a>,{' '}
						<a href='#0'>Romany Malco</a> <a href='#0'>...</a>
					</p>
					<p className='movie__option'>
						<strong>{t('movieListFull-age')}: </strong>
						<a href='#0'>13</a>
					</p>
					<div className='movie__btns'>
						<a href='#0' className='btn btn-md btn--warning'>
						{t('movieListFull-buyTicket')} {/* <span className='hidden-sm'> ამ ფილმზე</span> */}
						</a>
						<a href='#0' className='watchlist'>
						{t('movieListFull-addToFavorite')}
						</a>
					</div>
					<div className='preview-footer'>
						<div className='movie__rate'>
							<div className='score' />
							<span className='movie__rate-number'>170 {t('movieListFull-vote')}</span>{' '}
							<span className='movie__rating'>5.0</span>
						</div>
						<a href='#0' className='movie__show-btn'>
						{t('movieListFull-movieTime')}
						</a>
					</div>
				</div>
				<div className='clearfix' />
				{/* Time table (choose film start time)*/}
				<div className='time-select'>
					<div className='time-select__group group--first'>
						<div className='col-sm-3'>
							<p className='time-select__place'>Last Vegas (2013)</p>
						</div>
						<ul className='col-sm-6 items-wrap'>
							<li className='time-select__item' data-time='09:40'>
								09:40
							</li>
							<li className='time-select__item' data-time='13:45'>
								13:45
							</li>
							<li className='time-select__item' data-time='15:45'>
								15:45
							</li>
							<li className='time-select__item' data-time='19:50'>
								19:50
							</li>
							<li className='time-select__item' data-time='21:50'>
								21:50
							</li>
						</ul>
					</div>
					{/* end time table*/}
				</div>
				{/* end movie preview item */}
			</div>
		</React.Fragment>
	);
};

export default MovieListSingle;
