import React from 'react';

const Carrier = () => {
	return (
		<section className='container'>
			<div className='overflow-wrapper'>
				<div className='col-sm-12'>
					{/* <h2 class="page-heading">News</h2> */}
					{/* News post article*/}
					<article className='post post--news'>
						<h1>
							<a href='#0' className='post__title-link'>
								კარიერა
							</a>
						</h1>
						<p className='post__date'>1 აპრილი 2021</p>
						<div className='wave-devider' />
						<li>
							<a href='#0'>დამლაგებელი</a>
						</li>
						<li>
							{' '}
							<a href='#0'>ბილეთის კონტროლი</a>
						</li>
						<li>
							<a href='#0'>კინო მექანიკოსი</a>
						</li>
						<li>
							<a href='#0'>მოლარე-ბარმენი</a>
						</li>
						<li>
							<a href='#0'>ბილეთების სალაროს მოლარე</a>
						</li>
						<p>
							შპს " კინო საქართველო " აცხადებს ვაკანსიას დამლაგებლის პოზიციაზე.
						</p>
						<p className='post__text'>ძირითადი მოვალეობები :</p>
						<li>
							კინოთეატრის ტერიტორიის სისუფთავისა და ჰიგიენის მუდმივი კონტროლი.
						</li>
						<li>კინოთეატრის ტერიტორიის სისუფთავის უზრუნველყოფა.</li>
						<li>
							უშუალო ხელმძღვანელის მიერ მიცემული სხვა დავალებების შესრულება.
						</li>
						<p>მოთხოვნები და უნარ-ჩვევები:</p>
						<li>პუნქტუალურობა,</li>
						<li>დისციპლინისა და სისუფთავის დაცვა;</li>
						<li>დატვირთულ რეჟიმში მუშაობის უნარი;</li>
						<li>გუნდური მუშაობის უნარი.</li>
						<p>
							<span>კონკურსის ეტაპები:</span>
						</p>
						<p className='post__text'>განაცხადების გადარჩევა</p>
						<p className='post__text'>გასაუბრება</p>
						<p>
							დაინტერესების შემთხვევაში, გთხოვთ, გამოაგზავნოთ თქვენი CV შემდეგ
							მისამართზე: hr@kinosakartvelo.ge გთხოვთ, ელ-ფოსტის სათაურში
							(გრაფაში "subject") მიუთითოთ ვაკანსიის დასახელება. დავუკავშირდებით
							მხოლოდ შერჩეულ კანდიდატებს.
						</p>
						<p>
							შპს „კინო საქართველო“ აცხადებს ვაკანსიას ბილეთების კონტროლიორის
							პოზიციაზე.
						</p>
						<p>მოთხოვნები და უნარ ჩვევები :</p>
						<li>ინგლისური და რუსული ენების საშუალო დონეზე ცოდნა</li>
						<li>საოფისე პროგრამების ცოდნა;</li>
						<li>სასურველია სამუშაო გამოცდილება მომსახურების სფეროში.</li>
						<li>კომუნიკაბელურობა</li>
						<p>
							დაინტერესების შემთხვევაში, გთხოვთ, გამოაგზავნოთ თქვენი CV შემდეგ
							მისამართზე: hr@kinosakartvelo.ge გთხოვთ, ელფოსტის სათაურში
							(გრაფაში "subject") მიუთითოთ ვაკანსიის დასახელება.
						</p>
						<p>კონკურსის ეტაპები:</p>
						<li>განცხადების გადარჩევა</li>
						<li>გასაუბრება</li>
						<p>
							შპს " კინო საქართველო " აცხადებს ვაკანსიას კინო-მექანიკოსის
							პოზიციაზე:
						</p>
						<p>ძირითადი ფუნქციები:</p>
						<li>ფილმებისა და სარეკლამო რგოლების გაშვება შეუფერხებლად;</li>
						<li>
							გამოსახულებისა და ხმის პრობლემების იდენტიფიცირება და გამოსწორება
						</li>
						<li>თანამდებობასთან დაკავშირებული სხვა მოვალეობები;</li>
						<p>საკვალიფიკაციო მოთხოვნები:</p>
						<li>
							კომპიუტერული საოფისე აპლიკაციების და სხვადასხვა მოწყობილობების
							ცოდნა;
						</li>
						<li>კომპიუტერული პროგრამების დაინსტალირების ცოდნა;</li>
						<li>ქართული, რუსული და ინგლისური ენების ცოდნა;</li>
						<p>საჭირო უნარ - ჩვევები</p>
						<li>საქმის დაგეგმარებისა და ორგანიზების უნარი;</li>
						<li>კომუნიკაციის უნარი როგორც ვერბალური, ასევე წერითი;</li>
						<li>გუნდური მუშაობის უნარი;</li>
						<li>დატვირთულ რეჟიმში მუშაობის უნარი</li>
						<p>
							დაინტერესების შემთხვევაში, გთხოვთ, გამოაგზავნოთ თქვენი CV შემდეგ
							მისამართზე: hr@kinosakartvelo.ge გთხოვთ, ელ-ფოსტის სათაურში
							(გრაფაში "subject") მიუთითოთ ვაკანსიის დასახელება. ასევე შეგიძლიათ
							განაცხადები მოიტანოთ ადგილზე. დავუკავშირდებით მხოლოდ შერჩეულ
							კანდიდატებს. პს „კინო საქართველო“ აცხადებს ვაკანსიას მოლარე ბარში
							პოზიციაზე.
						</p>
						<p>მოვალეობები</p>
						<li>ინგრედიენტების მარაგების კონტროლი.</li>
						<li>სამზარეულოს სისუფთავის, ჰიგიენისა და წესრიგის უზრუნველყოფა</li>
						<li>სამზარეულოს გამართულად მუშაობის უზრუნველყოფა.</li>
						<li>სალაროსთან დაკავშირებული ყველა საქმიანობის წარმოება;</li>
						<li>სალაროში არსებული ნაღდი ფულის კონტროლი;</li>
						<li>გაყიდვასთან დაკავშირებული ყველა სხვა მოვალეობა;</li>
						<li>მომხმარებელთან კომუნიკაცია;</li>
						<li>მომხმარებლის კონსულტაცია</li>
						<li>პროდუქციის მიღებაში მონაწილეობა;</li>
						<p>ძირითადი მოთხოვნები:</p>
						<li>კომუნიკაბელური</li>
						<li>დისციპლინისა და სისუფთავის დაცვა;</li>
						<li>დატვირთულ რეჟიმში მუშაობის უნარი;</li>
						<li>გუნდური მუშაობის უნარი.</li>
						<p>კონკურსის ეტაპები:</p>
						<li>უმაღლესი განათლება</li>
						<li>კომუნიკაბელურობა</li>
						<li>კომუნიკაბელურობა</li>
						<li>
							მუშაობის მინიმუმ 1 წლიანი გამოცდილება მოლარე - ოპერატორის
							პოზიციაზე
						</li>
						<li>MS Office - პროგრამების სრულყოფილ დონეზე ცოდნა</li>
						<li>რუსული და ინგლისური ენების ცოდნა</li>
						<p>მოვალეობები</p>
						<li>სალაროსთან დაკავშირებული ყველა საქმიანობის წარმოება</li>
						<li>სალაროში არსებული ნაღდი ფულის კონტროლი</li>
						<li>დავალებების დროულად შესრულების უნარი</li>
						<li>გუნდური მუშაობის უნარი</li>
						<li>მოწესრიგებულობა</li>
						<li>პასუხისმგებლობის გრძნობის ქონა</li>
						<li>სტრესულ სიტუაციაში მუშაობის უნარი</li>
						<li>პრობლემების დროულად მშვიდად მოგვარების უნარი</li>
						<p>კონკურსის ეტაპები</p>
						<li>განცხადების გადარჩევა</li>
						<li>გასაუბრება</li>
						<p>
							დაინტერესების შემთხვევაში, გთხოვთ, გამოაგზავნოთ თქვენი CV შემდეგ
							მისამართზე: hr@kinosakartvelo.ge. გთხოვთ, ელ-ფოსტის სათაურში
							(გრაფაში "subject") მიუთითოთ ვაკანსიის დასახელება. დავუკავშირდებით
							მხოლოდ შერჩეულ კანდიდატებს.
						</p>
						<div className='tags'>
							<ul>
								<li className='item-wrap'>
									<a href='#0' className='tags__item'>
										კარიერა
									</a>
								</li>
								<li className='item-wrap'>
									<a href='#0' className='tags__item'>
										წესები
									</a>
								</li>
							</ul>
						</div>
						<div className='devider-huge' />
					</article>
				</div>
			</div>
		</section>
	);
};

export default Carrier;
