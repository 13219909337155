import React from 'react';


const SwiperVideo = () => {

    return (
        <React.Fragment>
        <div className="swiper-slide media-video">
            <a href='https://www.youtube.com/watch?v=9mkiY-37OG4' className="movie__media-item ">
                <img alt='' src="https://fastly.syfy.com/sites/syfy/files/styles/1140x640_hero/public/2021/02/boss-level.jpg?offset-x=0&offset-y=0"/>
            </a>
        </div>
        </React.Fragment>
    );
}


export default SwiperVideo;