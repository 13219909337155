import React from 'react';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';









const AnimatedSlider = ({data}) => {

	//three item in slider
	const sliderData = data.slice(0, 3);

    return(
			<Slider
				autoplay={3000}
				className="slider slider-wrapper">
				{sliderData.map((item, index) => (
						<div key={index}
							className="slide slider-content"
							style={{ background: `url(https://image.tmdb.org/t/p/original/${item.poster_path}) no-repeat center center` }}
						>
							<div className="inner">
								<h1>{item.title}</h1>
								<p>{item.overview}</p>
								<button>ბილეთი</button>
							</div>
						</div>
				))}
			</Slider>

    );
}


export default AnimatedSlider;