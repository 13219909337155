import React from 'react';

const Pagination = () => {
	return (
		<form
			id='film-and-time'
			className='booking-form'
			method='get'
			action='book2.html'
		>
			<input type='text' name='choosen-movie' className='choosen-movie' />
			<input type='text' name='choosen-city' className='choosen-city' />
			<input type='text' name='choosen-date' className='choosen-date' />
			<input type='text' name='choosen-cinema' className='choosen-cinema' />
			<input type='text' name='choosen-time' className='choosen-time' />
			<div className='booking-pagination'>
				<a href='#0' className='booking-pagination__prev hide--arrow'>
					<span className='arrow__text arrow--prev' />
					<span className='arrow__info' />
				</a>
				<a href='book2.html' className='booking-pagination__next'>
					<span className='arrow__text arrow--next'>შემდეგი ნაბიჯი</span>
					<span className='arrow__info'>აირჩიეთ ადგილი</span>
				</a>
			</div>
		</form>
	);
};

export default Pagination;
