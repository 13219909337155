import React from 'react';
import { useTranslation } from 'react-i18next';

const ContactInfo = () => {
	const { t } = useTranslation();
	return (
		<React.Fragment>
			<h2 className='page-heading'>{t('book3Buy-contact')}</h2>
			<form
				id='contact-info'
				method='post'
				noValidate
				className='form contact-info'
			>
				<div className='contact-info__field contact-info__field-mail'>
					<input
						type='email'
						name='user-mail'
						placeholder={t('book3Buy-email')}
						className='form__mail'
					/>
				</div>
				<div className='contact-info__field contact-info__field-tel'>
					<input
						type='tel'
						name='user-tel'
						placeholder={t('book3Buy-tell')}
						className='form__mail'
					/>
				</div>
			</form>
		</React.Fragment>
	);
};

export default ContactInfo;
