import React, { useState } from 'react';
import CarouselItem from './CarouselItem/CarouselItem';
import OwlCarousel from 'react-owl-carousel';
import '../../css/OwlCss/owl.carousel.min.css';
import '../../css/OwlCss/owl.theme.default.css';

function ComingSoon({ data }) {
	let carouselData = data.slice(0, 5);
	const [responsiveOwl, setResponsiveOwl] = useState({
		responsive: {
			0: {
				items: 1,
			},

			280: {
				items: 1,
			},

			540: {
				items: 3,
			},

			580: {
				items: 2,
			},

			760: {
				items: 2,
			},

			540: {
				items: 2,
			},

			1024: {
				items: 2,
			},

			1920: {
				items: 3,
			},
		},
	});

	return (
		<div className='col-sm-12'>
			<OwlCarousel
				autoWidth={false}
				autoplay={true}
				loop={true}
				items={5}
				dots={true}
				nav={true}
				touchDrag={true}
				responsive={responsiveOwl.responsive}
				className='owl-carousel owl-theme owl-loaded carousel-container--my container--for--nav'
			>
				<h2 className='page-heading coming--soon--title'>მალე კინოთეატრებში</h2>
				<div className='owl-stage-outer'>
					<div className='owl-stage'>
						{carouselData.map((car, index) => {
							return <CarouselItem key={Math.random() + index} carData={car} />;
						})}
					</div>
				</div>
			</OwlCarousel>
		</div>
	);
}

export default ComingSoon;
