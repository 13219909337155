import React from 'react';
import { useTranslation } from 'react-i18next';

function SilngeMovie({ singleData }) {
	const { t } = useTranslation();
	return (
		<div className='movie-beta__item '>
			{/*Cange Col Orange*/}
			<img
				alt='poster'
				src={`https://image.tmdb.org/t/p/original/${singleData.poster_path}`}
			/>{' '}
			{/* image size 380x600 */}
			<span className='best-rate'>5.0</span>
			<ul className='movie-beta__info'>
				<li>
					<span className='best-voted'>{singleData.title}</span>
				</li>
				<li>
					<p className='movie__time'>169 min</p>
					<p>Adventure | Drama | Fantasy </p>
					<p>38 comments</p>
				</li>
				<li className='last-block'>
					<a href='movie-page-full.html' className='slide__link'>
					{t('bestMovies-more')}
					</a>
				</li>
			</ul>
		</div>
	);
}

export default SilngeMovie;
