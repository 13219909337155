import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import logo from '../../images/new-logo.png';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import jQuery from 'jquery';
import useLocalStorage from 'use-local-storage';
window.$ = $;
window.jQuery = $;

const Header = () => {
	const { t } = useTranslation();

	const [country, setCountry] = useLocalStorage('ქარ');
	const [flag, setFlag] = useLocalStorage('Georgia');

	useEffect(() => {
		'use strict';

		// Mobile menu

		(function ($) {
			$.fn.mobileMenu = function (options) {
				if (!options.triggerMenu)
					throw new Error('Object options.triggerMenu required!!!');
				if (!options.subMenuTrigger)
					throw new Error('Object options.triggerMenu required!!!');
				var animationSpeed = options.animationSpeed || 500;

				//Initialization variables
				var $navigationList = this;

				if ('ontouchstart' in window) {
					$(options.triggerMenu).on('touchstart', menuToggle);
					$navigationList
						.find('li ' + options.subMenuTrigger)
						.on('touchstart', subMenuToggle);
				} else {
					$(options.triggerMenu).on('click', menuToggle);
					$navigationList
						.find('li ' + options.subMenuTrigger)
						.on('click', subMenuToggle);
				}

				//navigation-toggle

				function menuToggle(e) {
					e.preventDefault();
					$navigationList.slideToggle(animationSpeed, setClass);
					$(this).toggleClass('open-menu');
				}

				//navigation list item toggle

				function subMenuToggle(e) {
					e.preventDefault();
					var subMenu = $(this).toggleClass('plus').parent('li').children('ul');

					$(this)
						.parent('li')
						.parent('ul')
						.find('li ul.showen-c')
						.not(subMenu)
						.slideUp(animationSpeed, setClass)
						.siblings('.sub-nav-toggle')
						.toggleClass('plus');
					subMenu.slideToggle(animationSpeed, setClass);
				}

				return this;
			};

			// function change style="display:none" to class="hidden"
			function setClass() {
				var $this = $(this);

				if ($this.attr('style') && $this.css('display') == 'none') {
					$this
						.removeAttr('style')
						.removeClass('showen-c')
						.addClass('hidden-c');
				}

				if ($this.attr('style') && $this.css('display') == 'block') {
					$this
						.removeAttr('style')
						.removeClass('hidden-c')
						.addClass('showen-c');
				}
			}
		})(jQuery);

		//2. Mobile menu
		//Init mobile menu
		$('#navigation').mobileMenu({
			triggerMenu: '#navigation-toggle',
			subMenuTrigger: '.sub-nav-toggle',
			animationSpeed: 500,
		});
	}, []);

	return (
		<React.Fragment>
			{/* Header section  */}
			<header className='header-wrapper header-wrapper--home'>
				<div className='container'>
					{/* Logo link */}
					<Link to='/'>
						<span className='logo'>
							<img alt='logo' src={logo} />
							<p>{t('kinosakartvelo')}</p>
						</span>
					</Link>

					{/* Main website navigation</a> */}
					<nav id='navigation-box'>
						{/* Toggle for mobile menu mode  */}
						<a href='#0' id='navigation-toggle'>
							<span className='menu-icon'>
								<span
									className='icon-toggle'
									role='button'
									aria-label='Toggle Navigation'
								>
									<span className='lines'></span>
								</span>
							</span>
						</a>

						{/* Link navigation */}

						<ul id='navigation'>
							<li>
								<span className='sub-nav-toggle plus'></span>
								<a href='#0'>{t('menu')}</a>
								<ul className='mega-menu container'>
									<li className='col-md-3 mega-menu__coloum'>
										{/* <!-- <h4 className="mega-menu__heading">Now in the cinema</h4> --> */}
										<ul className='mega-menu__list'>
											<li className='mega-menu__nav-item'>
												<Link to='/rulesofconduct'>{t('rules')}</Link>
											</li>
											<li className='mega-menu__nav-item'>
												<Link to='/gallery'>{t('gallery')}</Link>
											</li>
										</ul>
									</li>
									<li className='col-md-3 mega-menu__coloum mega-menu__coloum'>
										<ul className='mega-menu__list'>
											<li className='mega-menu__nav-item'>
												<Link to='/contact'>{t('contact')}</Link>
											</li>
											<li className='mega-menu__nav-item'>
												{' '}
												<Link to='/carrier'>{t('carrier')}</Link>
											</li>
										</ul>
									</li>
									<li className='col-md-3 mega-menu__coloum'>
										{/* <!-- <h4 className="mega-menu__heading">Ending soon</h4> --> */}
										<ul className='mega-menu__list'>
											<li className='mega-menu__nav-item'>
												<Link to='/advertisement'>{t('ads')}</Link>
											</li>
										</ul>
									</li>
									<li className='col-md-3 mega-menu__coloum mega-menu__coloum'>
										<ul className='mega-menu__list'>
											<li className='mega-menu__nav-item'>
												<Link to='/bastibubu'>{t('bastiBubu-a')}</Link>
											</li>
										</ul>
									</li>
								</ul>
							</li>
						</ul>
					</nav>

					{/* <!-- Additional header buttons / Auth and direct link to booking--> */}
					<div className='control-panel'>
						{/* Language Change Drop Down */}
						<div className='switch-lang'>
							<div
								onClick={() => {
									i18next.changeLanguage('ge');
								}}
								className='current-lang'
							>
								<img
									className='lang-flag'
									src={`https://cdn2.iconfinder.com/data/icons/world-flag-icons/128/Flag_of_${
										flag ? flag : 'Georgia'
									}.png`}
								/>
								<p className='lang-text'>{country ? country : 'Geo'}</p>
							</div>
							<div className='lang-dropdown'>
								<div
									onClick={() => {
										i18next.changeLanguage('en');
										setCountry('Eng');
										setFlag('United_Kingdom');
									}}
									className='selecting-lang'
								>
									<img
										className='lang-flag'
										src='https://cdn2.iconfinder.com/data/icons/world-flag-icons/128/Flag_of_United_Kingdom.png'
									/>
									<p className='lang-text'>Eng</p>
								</div>
								<div
									onClick={() => {
										i18next.changeLanguage('ge');
										setCountry('ქარ');
										setFlag('Georgia');
									}}
									className='selecting-lang'
								>
									<img
										className='lang-flag'
										src='https://cdn2.iconfinder.com/data/icons/world-flag-icons/128/Flag_of_Georgia.png'
									/>
									<p className='lang-text'>ქარ</p>
								</div>
								<div
									onClick={() => {
										i18next.changeLanguage('ru');
										setCountry('Pус');
										setFlag('Russia');
									}}
									className='selecting-lang'
								>
									<img
										className='lang-flag'
										src='https://cdn2.iconfinder.com/data/icons/world-flag-icons/128/Flag_of_Russia.png'
									/>
									<p className='lang-text'>Pус</p>
								</div>
							</div>
						</div>
						{/* Language Change Drop Down End*/}
						<Link
							to='/movielist'
							href='/kino-html/movie-list-full.html'
							className='btn btn-md btn--warning btn--book btn- '
						>
							{t('movies')}
						</Link>
					</div>
				</div>
			</header>
		</React.Fragment>
	);
};

export default Header;
